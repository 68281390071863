import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import Loader from './Loader';

export default class FullscreenLoader extends Component {
  static propTypes = {};

  static containerNode = null;

  constructor() {
    super();
    if (!FullscreenLoader.containerNode) {
      const node = document.createElement('div');
      document.body.appendChild(node);
      FullscreenLoader.containerNode = node;
    }
  }

  render() {
    return createPortal(
      <Loader
        {...this.props}
        alwaysShowLabel
        backgroundAlpha={this.props.backgroundAlpha || 'dark'}
        fullscreen
      />,
      FullscreenLoader.containerNode,
    );
  }
}
