import { bool } from 'prop-types';
import React from 'react';

import { getText } from 'shared/utils/Text';

import AlertDialog from './AlertDialog';

const LockedAlertDialog = ({ isShared, ...props }) => (
  <AlertDialog
    title={getText('grid-lock-headline')}
    details={getText(isShared ? 'grid-lock-share-body' : 'grid-lock-body')}
    {...props}
  />
);

LockedAlertDialog.propTypes = {
  isShared: bool,
};

export default LockedAlertDialog;
