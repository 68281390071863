import classnames from 'classnames';
import { bool, func, node, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  onClick,
  className,
  disabled,
  buttonRef,
  children,
  ...passProps
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames('button-component', className)}
    ref={buttonRef}
    disabled={disabled}
    {...passProps}
  >
    {children}
  </button>
);

Button.propTypes = {
  onClick: func.isRequired,
  className: string,
  disabled: bool,
  buttonRef: func,
  children: node,
};

export default Button;

const LinkButton = ({
  to,
  className,
  disabled,
  buttonRef,
  children,
  ...passProps
}) => (
  <Link
    to={to}
    className={classnames('button-component', className)}
    ref={buttonRef}
    disabled={disabled}
    {...passProps}
  >
    {children}
  </Link>
);

LinkButton.propTypes = {
  to: string.isRequired,
  className: string,
  disabled: bool,
  buttonRef: func,
  children: node,
};

export { LinkButton };
