import PropTypes from 'prop-types';
import React from 'react';

import ErrorCircle from 'shared/components/icon/ErrorCircle';
import Bugsnag from 'shared/utils/bugsnag';

import styles from './index.module.scss';

const FallbackComponent = () => (
  <div className={styles.container}>
    <ErrorCircle className={styles.icon} />
    <h2 className={styles.header}>Ett oväntat fel inträffade</h2>
    <p className={styles.text}>
      Prova igen genom att{' '}
      <button
        type="button"
        className="text-button"
        onClick={() => window.location.reload()}
      >
        ladda om
      </button>{' '}
      sidan. Om problemen kvarstår vänligen{' '}
      <a href="mailto:support@jokersystemet.se">kontakta supporten</a>.
    </p>
  </div>
);

const BugsnagErrorBoundary = Bugsnag.getPlugin('react');

const ErrorBoundary = ({ children }) => (
  <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
    {children}
  </BugsnagErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
