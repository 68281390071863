import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

const SLUG_PREFIX = 'joker__';

/*
 * Props selectors
 */
export const slugSelector = (_, props) => SLUG_PREFIX + props.slug;
export const categorySelector = (_, props) => props.category;
export const pageIdSelector = (_, props) => props.pageId;

export const getPages = state => state.content.pages;

export const getList = createSelector(
  [state => state.content.list, categorySelector],
  (list, category) => list[category],
);

export const hasContentList = createSelector(getList, list => list.length > 0);

const getListEntity = createSelector([getList, slugSelector], (list, slug) =>
  list.find(item => item.url_alias === slug),
);

export const getPage = createSelector(
  [getPages, pageIdSelector],
  (dict, id) => dict[id],
);

export const getPageIdFromSlug = createSelector(getListEntity, entity =>
  entity ? entity.id : undefined,
);

export const getPageTitleFromSlug = createSelector(getListEntity, entity =>
  entity ? entity.title : undefined,
);

export const isInvalidSlug = createSelector(
  [getList, getListEntity],
  (list, entity) => list.length > 0 && !entity,
);

export const getFrontPages = createSelector(
  [getList, getPages],
  (list, allPages) =>
    orderBy(
      list.map(item => ({ ...item, ...allPages[item.id] })),
      'url_alias',
      'asc',
    ),
);

export const isInstructionsPage = createSelector(
  getListEntity,
  entity => entity && entity.url_alias === `${SLUG_PREFIX}instruktionsfilmer`,
);

export const isFaqPage = createSelector(
  getListEntity,
  entity => entity && entity.url_alias === `${SLUG_PREFIX}faq`,
);

export const shouldFetchList = createSelector(
  [hasContentList, state => state.content.loadingList, categorySelector],
  (hasList, loading, category) => !hasList && !loading[category],
);

export const shouldFetchPage = createSelector(
  [getPage, state => state.content.loadingPages, pageIdSelector],
  (page, loadingPages, pageId) => !page && !loadingPages[pageId],
);
