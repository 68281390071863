import React from 'react';

import Icon from './Icon';

const JokerWiz = ({ className, width, height }) => (
  <Icon
    name="jokerwiz"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 50 50"
  >
    <g
      id="Jokersystemet-Sport-3.0"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="startsida-mobil">
        <g id="Group-4">
          <path
            d="M3,0 L47,0 C48.6568542,-3.04359188e-16 50,1.34314575 50,3 L50,47 C50,48.6568542 48.6568542,50 47,50 L3,50 C1.34314575,50 2.02906125e-16,48.6568542 0,47 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 Z"
            id="Rectangle-3-Copy-14"
            fill="#000000"
          ></path>
          <path
            d="M21.9945313,30.9929687 L18.1636719,30.9929687 L17.0152344,25.7675781 L16.9500651,25.4768232 C16.8996312,25.2349836 16.8352217,24.8971376 16.7568359,24.4632812 L16.6745481,23.9951894 C16.5981565,23.5470937 16.5394533,23.158986 16.4984375,22.8308594 L16.4542411,23.1566409 C16.4118304,23.445703 16.3554691,23.7787091 16.2851563,24.1556641 C16.2064059,24.5778537 16.1285314,24.9729135 16.0515314,25.340851 L14.8003906,30.9929687 L14.8003906,30.9929687 L10.9695313,30.9929687 L8,19 L11.1253906,19 L12.4296875,25.0128906 L12.5428546,25.5300176 C12.7773065,26.6191769 12.9419266,27.505 13.0367188,28.1875 C13.1018883,27.6761693 13.2141581,26.99712 13.3735309,26.1503346 L13.5266097,25.3607016 C13.6692347,24.6475761 13.8039837,24.0394556 13.9308594,23.5363281 L14.9890625,19 L17.9914063,19 L19.0167969,23.5363281 C19.1699226,24.1727571 19.3251399,24.8950106 19.4824522,25.7031055 L19.6653295,26.6750202 C19.7827944,27.3215626 19.8646632,27.8257193 19.9109375,28.1875 L19.9374784,27.9809898 C20.0242663,27.3539009 20.2095586,26.3700108 20.4933594,25.0292969 L21.8222656,19 L24.9476563,19 L21.9945313,30.9929687 Z M26.6647135,30.9929687 L26.6647135,19 L29.9213542,19 L29.9213542,30.9929687 L26.6647135,30.9929687 Z M41.2278646,30.9929687 L31.8845052,30.9929687 L31.8845052,28.9914062 L37.0770833,21.6167969 L32.0075521,21.6167969 L32.0075521,19 L41.0966146,19 L41.0966146,20.9933594 L35.9040364,28.3761719 L41.2278646,28.3761719 L41.2278646,30.9929687 Z"
            id="WIZ"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </Icon>
);

export default JokerWiz;
