import React from 'react';

import Icon from './Icon';

const Edit = ({ className }) => (
  <Icon name="edit" className={className} viewBox="0 0 20 20">
    <polygon points="0,20 1,15 5,19 " />
    <rect
      x="1.93"
      y="8.17"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.1421 9.5858)"
      width="14.14"
      height="5.66"
    />
    <polygon points="14,2 18,6 20,4 20,2 18,0 16,0 " />
  </Icon>
);

export default Edit;
