import debounce from 'lodash/debounce';
import React, { Component } from 'react';

import { getDisplayName } from 'shared/utils';

const instances = [];

const getWindowWidth = () => window.innerWidth;
const getWindowHeight = () => window.innerHeight;
const getInnerWidth = () => document.documentElement.clientWidth;
const getInnerHeight = () => document.documentElement.clientHeight;

const onResize = debounce(() => {
  const windowWidth = getWindowWidth();
  const windowHeight = getWindowHeight();
  const innerWidth = getInnerWidth();
  const innerHeight = getInnerHeight();
  instances.forEach(instance => {
    let shouldUpdateState = true;
    // Update only if window width change crosses over a breakpoint threshold
    if (instance.breakpoints.length > 0) {
      const oldWidth = instance.state.windowWidth;
      shouldUpdateState = instance.breakpoints.some(breakPoint =>
        oldWidth > windowWidth
          ? breakPoint <= oldWidth && breakPoint >= windowWidth
          : breakPoint >= oldWidth && breakPoint <= windowWidth,
      );
    }
    if (shouldUpdateState) {
      instance.setState({ windowWidth, windowHeight, innerWidth, innerHeight });
    }
  });
}, 200);

const withWindowSize =
  (breakpoints = []) =>
  WrappedComponent => {
    class WithWindowSize extends Component {
      static propTypes = {};

      static displayName = `WithWindowSize(${getDisplayName(
        WrappedComponent,
      )})`;

      breakpoints = breakpoints;

      state = {
        windowWidth: getWindowWidth(),
        windowHeight: getWindowHeight(),
        innerWidth: getInnerWidth(),
        innerHeight: getInnerHeight(),
      };

      componentDidMount() {
        if (instances.length === 0) {
          window.addEventListener('resize', onResize);
        }
        instances.push(this);
      }

      componentWillUnmount() {
        const index = instances.indexOf(this);
        instances.splice(index, 1);
        if (instances.length === 0) {
          window.removeEventListener('resize', onResize);
        }
      }

      render() {
        return <WrappedComponent {...this.state} {...this.props} />;
      }
    }

    return WithWindowSize;
  };

export default withWindowSize;
