import config from 'config';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ApplicationInaccessible from 'shared/components/ApplicationInaccessible';

/**
 * If the user is seen as offline render an offline component until he/she comes
 * back online.
 * If the user is seen as online make a ping request to the api health endpoint and
 * if we don't get a good response the server has switched over to maintenance mode.
 * Render a maintenance component instead of the normal app until we get a good response.
 *
 */
const VerifyApplicationAccessible = ({ children }) => {
  const [maintenanceMode, setMaintenanceMode] = useState();
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    let maintenanceMode = false;
    let timer;

    async function ping() {
      if (!window.navigator.onLine) {
        setOffline(true);
      } else {
        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_BUILD_TARGET === 'prod'
                ? process.env.REACT_APP_BACKEND_DOMAIN
                : // If the backend has been overriden we should ping that server
                // Not needed on local dev, only staging, since dev uses a proxy
                process.env.REACT_APP_BUILD_TARGET === 'stage'
                ? config.backendHost
                : ''
            }/${process.env.REACT_APP_API_PATH}/monitor/jokerMainHealth`,
          );
          maintenanceMode = response.status > 202;
        } catch (error) {
          maintenanceMode = true;
          console.error(error);
        }
      }

      setMaintenanceMode(maintenanceMode);

      // Check again in five seconds
      if (maintenanceMode) {
        timer = window.setTimeout(() => ping(), 5000);
      }
    }

    ping();

    return () => {
      window.clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    function onlineListener() {
      if (window.navigator.onLine) {
        setOffline(false);
      }
    }

    if (offline) {
      window.addEventListener('online', onlineListener);
    }

    return () => {
      window.removeEventListener('online', onlineListener);
    };
  }, [offline]);

  if (maintenanceMode === undefined) {
    return null;
  }

  if (maintenanceMode === true) {
    return <ApplicationInaccessible reason="maintenance" />;
  }

  if (offline) {
    return <ApplicationInaccessible reason="offline" />;
  }

  return children;
};

VerifyApplicationAccessible.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VerifyApplicationAccessible;
