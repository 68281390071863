export const CHANGE_MENU_CATEGORY = 'CHANGE_MENU_CATEGORY';
export const SET_SELECTED_MENU_ITEM = 'SET_SELECTED_MENU_ITEM';
export const CLEAR_SELECTED_MENU_ITEM = 'CLEAR_SELECTED_MENU_ITEM';
export const CHANGE_DISABLED_MENU_ITEMS = 'CHANGE_DISABLED_MENU_ITEMS';
export const TOGGLE_MANUAL_UPDATE_BUTTON = 'TOGGLE_MANUAL_UPDATE_BUTTON';

export const UPDATE_PAGE_VISIBILITY = 'UPDATE_PAGE_VISIBILITY';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const CHANGE_UI_SCALE = 'CHANGE_UI_SCALE';
export const ADD_SITE_MESSAGE = 'ADD_SITE_MESSAGE';
export const DISMISS_SITE_MESSAGE_BY_SERVER_ID =
  'DISMISS_SITE_MESSAGE_BY_SERVER_ID';
export const DISMISS_SITE_MESSAGE_BY_CLIENT_ID =
  'DISMISS_SITE_MESSAGE_BY_CLIENT_ID';
export const DISMISS_SITE_MESSAGE_BY_TYPE = 'DISMISS_SITE_MESSAGE_BY_TYPE';
export const LOCATION_PATHNAME_CHANGE = 'LOCATION_PATHNAME_CHANGE';

export const REQUEST_PROMO_MESSAGE = 'REQUEST_PROMO_MESSAGE';
export const RECEIVE_PROMO_MESSAGE = 'RECEIVE_PROMO_MESSAGE';
export const RECEIVE_PROMO_ERROR = 'RECEIVE_PROMO_ERROR';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const POP_DIALOG = 'POP_DIALOG';
export const POP_DIALOG_BY_TYPE = 'POP_DIALOG_BY_TYPE';
export const TOGGLE_LOCK_DIALOG = 'TOGGLE_LOCK_DIALOG';

export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_CURRENT_USER_ERROR = 'RECEIVE_CURRENT_USER_ERROR';
export const REQUEST_LOGIN_USER = 'REQUEST_LOGIN_USER';
export const RECEIVE_LOGIN_USER = 'RECEIVE_LOGIN_USER';
export const RECEIVE_LOGIN_USER_ERROR = 'RECEIVE_LOGIN_USER_ERROR';
export const REQUEST_LOGOUT_USER = 'REQUEST_LOGOUT_USER';
export const RECEIVE_LOGOUT_USER = 'RECEIVE_LOGOUT_USER';
export const RECEIVE_LOGOUT_USER_ERROR = 'RECEIVE_LOGOUT_USER_ERROR';
export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';

export const REQUEST_INSTRUCTIONS = 'REQUEST_INSTRUCTIONS';
export const RECEIVE_INSTRUCTIONS = 'RECEIVE_INSTRUCTIONS';
export const RECEIVE_INSTRUCTIONS_ERROR = 'RECEIVE_INSTRUCTIONS_ERROR';

export const STOMP_SUBSCRIBE = 'STOMP_SUBSCRIBE';
export const STOMP_UNSUBSCRIBE = 'STOMP_UNSUBSCRIBE';
export const STOMP_RECEIVE_DATA = 'STOMP_RECEIVE_DATA';

export const REQUEST_CONTENT_LIST = 'REQUEST_CONTENT_LIST';
export const RECEIVE_CONTENT_LIST = 'RECEIVE_CONTENT_LIST';
export const RECEIVE_CONTENT_LIST_ERROR = 'RECEIVE_CONTENT_LIST_ERROR';
export const REQUEST_CONTENT_PAGE = 'REQUEST_CONTENT_PAGE';
export const RECEIVE_CONTENT_PAGE = 'RECEIVE_CONTENT_PAGE';
export const RECEIVE_CONTENT_PAGE_ERROR = 'RECEIVE_CONTENT_PAGE_ERROR';

export const REQUEST_UPLOAD_BET = 'REQUEST_UPLOAD_BET';
export const RECEIVE_UPLOAD_BET = 'RECEIVE_UPLOAD_BET';
export const RECEIVE_UPLOAD_BET_ERROR = 'RECEIVE_UPLOAD_BET_ERROR';
export const CLEAR_ARCHIVE_UPLOAD_ERROR = 'CLEAR_ARCHIVE_UPLOAD_ERROR';

export const TRACK_USER = 'TRACK_USER';

export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
export const RECEIVE_SETTINGS_ERROR = 'RECEIVE_SETTINGS_ERROR';
export const REQUEST_UPDATE_SETTINGS = 'REQUEST_UPDATE_SETTINGS';
export const RECEIVE_UPDATE_SETTINGS = 'RECEIVE_UPDATE_SETTINGS';
export const RECEIVE_UPDATE_SETTINGS_ERROR = 'RECEIVE_UPDATE_SETTINGS_ERROR';

export const GTM_EVENT = 'GTM_EVENT';

export const REQUEST_CHANGE_LIVE_PARTICIPATION =
  'REQUEST_CHANGE_LIVE_PARTICIPATION';
export const RECEIVE_CHANGE_LIVE_PARTICIPATION =
  'RECEIVE_CHANGE_LIVE_PARTICIPATION';
export const RECEIVE_CHANGE_LIVE_PARTICIPATION_ERROR =
  'RECEIVE_CHANGE_LIVE_PARTICIPATION_ERROR';
