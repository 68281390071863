import classnames from 'classnames';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const SelectInput = ({
  children,
  className,
  disabled = false,
  onChange,
  theme = 'blue',
  value,
  width = '150',
}) => (
  <div
    className={classnames(styles.container, className, {
      [styles.blue]: theme === 'blue',
      [styles.transparent]: theme === 'transparent',
      [styles.width55]: width === '55',
      [styles.width80]: width === '80',
      [styles.width125]: width === '125',
      [styles.width150]: width === '150',
      [styles.width220]: width === '220',
    })}
  >
    <select
      data-value={value}
      disabled={disabled}
      onChange={onChange}
      value={value || ''}
    >
      {children}
    </select>

    <span className={styles.arrowContainer}>
      <span className={styles.arrow} />
    </span>
  </div>
);

SelectInput.propTypes = {
  children: PropTypes.node.isRequired, // <option/>
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOf(['55', '80', '125', '150', '220', 'auto']),
};

const NumberSelectInput = ({ onChange, ...props }) => {
  const onNumberChange = e => {
    const value = e.currentTarget.value;

    if (value) {
      onChange(Number.parseInt(value, 10));
    } else {
      onChange();
    }
  };

  return <SelectInput width="55" onChange={onNumberChange} {...props} />;
};

NumberSelectInput.propTypes = {
  children: PropTypes.node.isRequired, // <option/>
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

const NumberRangeSelectInput = ({
  defaultOption,
  end,
  maxValue,
  minValue,
  start,
  ...props
}) => (
  <NumberSelectInput {...props}>
    {defaultOption}
    {range(start, end).map(idx => (
      <option
        disabled={(minValue && idx < minValue) || (maxValue && idx > maxValue)}
        key={idx}
        value={idx}
      >
        {idx}
      </option>
    ))}
  </NumberSelectInput>
);

NumberRangeSelectInput.propTypes = {
  defaultOption: PropTypes.element, // <option/>
  end: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  start: PropTypes.number.isRequired,
  value: PropTypes.number,
};

export default SelectInput;
export { NumberRangeSelectInput, NumberSelectInput };
