let data = {};

export async function load() {
  data = await import(/* webpackChunkName: "sv" */ '../sv.json');
}

// Deprecated
export function get(...args) {
  return getText(...args);
}

export function hasText(id) {
  return data[id] ? true : false;
}

export function getText(id, defaultValue) {
  let str = data[id];

  if (str === undefined) {
    str = defaultValue != null ? defaultValue : id;
  }

  if (str && str.replace) {
    for (let n = 1; n < arguments.length; n++) {
      str = str.replace(`%${n}`, arguments[n]);
    }
  }

  return str;
}

export function uppperFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
