import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AsyncAtgApp from 'shared/components/AsyncAtgApp';
import AsyncCalendarApp from 'shared/components/AsyncCalendarApp';
import AsyncLiveApp from 'shared/components/AsyncLiveApp';
import AsyncNewsApp from 'shared/components/AsyncNewsApp';
import AsyncSportApp from 'shared/components/AsyncSportApp';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import JokerwizInfoPage from 'shared/components/JokerwizInfoPage';
import FullscreenLoader from 'shared/components/loader/FullscreenLoader';
import UiScale from 'shared/components/UiScale';
import { MESSAGES_PRIVATE, MESSAGES_PUBLIC } from 'shared/constants/StompKind';
import AuthRoute from 'shared/containers/AuthRoute';
import ContentRoute from 'shared/containers/ContentRoute';
import Dialogs from 'shared/containers/Dialogs';
import ErrorBoundRoute from 'shared/containers/ErrorBoundRoute';
import LoginPage from 'shared/containers/LoginPage';
import LogoutPage from 'shared/containers/LogoutPage';
import MenuContainer from 'shared/containers/MenuContainer';
import SiteMessagesContainer from 'shared/containers/SiteMessagesContainer';
import SubscriptionsPage from 'shared/containers/SubscriptionsPage';
import {
  addSubscription,
  fetchUser,
  hideLoader,
  onLocationPathnameChange,
  updatePageVisibility,
} from 'shared/redux/actions';
import { ui, user } from 'shared/redux/selectors';
import Bugsnag from 'shared/utils/bugsnag';
import { load as loadText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

const mapStateToProps = state => ({
  loader: ui.getLoader(state),
  hasUserData: user.hasServerState(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideLoader,
      fetchUser,
      onLocationPathnameChange,
      updatePageVisibility,
      addSubscription,
    },
    dispatch,
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class App extends Component {
      state = {
        appInitialized: false,
      };

      handlePageVisibilityChange = () => {
        this.props.updatePageVisibility(document.hidden);
      };

      async componentDidMount() {
        const { fetchUser, hideLoader, updatePageVisibility, addSubscription } =
          this.props;

        // Subscribe to messages shared by all users
        addSubscription('/topic/userMessages', MESSAGES_PUBLIC);

        // Subscribe to messages for current user
        addSubscription('/user/queue/messages', MESSAGES_PRIVATE);

        try {
          await Promise.all([
            fetchUser(),
            loadText(),
            updatePageVisibility(document.hidden),
          ]);

          document.addEventListener(
            'visibilitychange',
            this.handlePageVisibilityChange,
          );

          this.setState({
            appInitialized: true,
          });
          hideLoader();
        } catch (error) {
          console.error(`🔥 ${error}`);
          Bugsnag.notify(error);
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
          nextProps.onLocationPathnameChange(nextProps.location.pathname);
        }
      }

      render() {
        const { appInitialized } = this.state;
        const { loader, hasUserData, location } = this.props;
        const { pathname } = location;

        const isAtgPath = pathname.indexOf('/trav') === 0;
        const isSportPath = pathname.indexOf('/trav') === 0;

        const fullscreenLoader = (
          <FullscreenLoader
            backgroundAlpha={
              isAtgPath ? 'dark' : isSportPath ? 'none' : 'light'
            }
            {...loader}
          />
        );

        if (!appInitialized || !hasUserData) {
          return fullscreenLoader;
        }

        return (
          <Fragment>
            {fullscreenLoader}
            <Dialogs />
            <UiScale />
            <ErrorBoundary>
              <SiteMessagesContainer />
            </ErrorBoundary>
            {pathname !== '/login' && pathname !== '/logout' && (
              <header className="page-top">
                <MenuContainer />
              </header>
            )}
            <Switch>
              <ErrorBoundRoute path="/" exact component={AsyncCalendarApp} />
              <ErrorBoundRoute path="/login" component={LoginPage} />
              <ErrorBoundRoute path="/logout" component={LogoutPage} />
              <ErrorBoundRoute path="/om-wiz" component={JokerwizInfoPage} />
              <ErrorBoundRoute
                path="/abonnemang"
                component={SubscriptionsPage}
              />
              <ErrorBoundRoute
                component={AsyncLiveApp}
                exact
                path={getUrl.live(':section?')}
              />
              <ErrorBoundRoute path="/nyheter" component={AsyncNewsApp} />
              <ErrorBoundRoute
                path="/trav/dela/:shareCode"
                component={AsyncAtgApp}
              />
              <ErrorBoundRoute
                path="/sport/dela/:shareCode"
                component={AsyncSportApp}
              />
              <AuthRoute path="/trav/:betId" component={AsyncAtgApp} />
              <AuthRoute path="/sport/:betId" component={AsyncSportApp} />
              <AuthRoute path="/sport" component={AsyncSportApp} />
              <AuthRoute path="/overodds" component={AsyncSportApp} />

              {/* Expand share urls */}
              <Redirect
                exact
                path="/s/:shareCode"
                to="/sport/dela/:shareCode/resultat"
              />
              <Redirect exact path="/t/:shareCode" to="/trav/dela/:shareCode" />

              {/* Redirect legacy ATG share url */}
              <Redirect
                exact
                path="/share/:shareCode"
                to="/trav/dela/:shareCode"
              />

              {process.env.NODE_ENV === 'development' && (
                <Route
                  path="/demo"
                  component={
                    require('shared/components/AsyncComponentDemoApp').default
                  }
                />
              )}

              <ErrorBoundRoute path="/:slug" component={ContentRoute} />
            </Switch>
          </Fragment>
        );
      }
    },
  ),
);
