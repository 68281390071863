import config from 'config';
import get from 'lodash/get';
import intersectionWith from 'lodash/intersectionWith';
import isString from 'lodash/isString';
import { RSAA } from 'redux-api-middleware';

import { REQUEST_CONTENT_LIST, REQUEST_CONTENT_PAGE } from '../actionTypes';

const isContentApiCall = rsaa =>
  intersectionWith(
    [REQUEST_CONTENT_LIST, REQUEST_CONTENT_PAGE],
    rsaa.types,
    (a, b) => (isString(b) ? b === a : b.type === a),
  ).length > 0;

const isTrsportOrTraisApiCall = endpoint =>
  endpoint.indexOf(config.trsportApi) === 0 ||
  endpoint.indexOf(config.traisApi) === 0;

const middleware = store => next => action => {
  const callAPI = action[RSAA];

  // Check if this action is a redux-api-middleware action
  if (callAPI) {
    const isContentApi = isContentApiCall(callAPI);

    // Prepend API base URL to endpoint if it does not already contain a valid base URL
    if (!/^((http|https|ftp):\/\/)/i.test(callAPI.endpoint)) {
      const baseUrl = isContentApi
        ? `${config.contentApi}/`
        : isTrsportOrTraisApiCall(callAPI.endpoint)
        ? ''
        : `${config.jokerApi}/`;
      callAPI.endpoint = baseUrl + callAPI.endpoint;
    }

    // Default to GET if method is undefined
    if (callAPI.method == null) {
      callAPI.method = 'GET';
    }

    // Default to set credentials to send cross domain cookies
    if (callAPI.credentials == null) {
      callAPI.credentials = 'include';
    }

    // Set headers to empty object if undefined
    if (callAPI.headers == null) {
      callAPI.headers = {};
    }

    // Set Content-Type to application/json if Content-Type does not already have a value
    if (get(callAPI.headers, 'Content-Type', null) == null) {
      callAPI.headers['Content-Type'] = 'application/json';
    }

    if (
      get(callAPI.headers, 'Content-Type', null) === 'application/octet-stream'
    ) {
      //If file upload we let the browser handle content-type and multipart delimiters.
      delete callAPI.headers['Content-Type'];
    }

    if (process.env.REACT_APP_BUILD_TARGET !== 'prod' && isContentApi) {
      callAPI.headers['Authorization'] = process.env.REACT_APP_CONTENT_API_AUTH;
    }

    if (process.env.NODE_ENV === 'development') {
      const { bailout, method, endpoint } = callAPI;
      const willBailout =
        (typeof bailout === 'boolean' && bailout) ||
        (typeof bailout === 'function' && bailout(store.getState()));
      if (!willBailout) {
        console.info(`${method}: ${endpoint}`);
      }
    }
  }

  // Pass the FSA to the next action
  return next(action);
};

export default middleware;
