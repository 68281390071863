let backendHost =
  // Proxied on local dev, see setupProxy.js
  process.env.REACT_APP_BUILD_TARGET === 'dev'
    ? ''
    : process.env.REACT_APP_BACKEND_DOMAIN;

if (process.env.REACT_APP_BUILD_TARGET !== 'prod') {
  try {
    let overridenHost;

    // First try backend override from url param
    if (process.env.REACT_APP_BUILD_TARGET === 'stage') {
      const paramsBackend = new URLSearchParams(document.location.search).get(
        'backend',
      );
      if (paramsBackend != null) {
        window.localStorage.setItem('backend-domain', paramsBackend);
        overridenHost = paramsBackend;
      }
    }

    // Secondary check local storage
    overridenHost =
      overridenHost || window.localStorage.getItem('backend-domain');

    if (overridenHost != null) {
      backendHost = overridenHost;
    }
  } catch {
    // Ignore
  }
}

const config = {
  backendHost,
  jokerApi: `${backendHost}/${process.env.REACT_APP_API_PATH}`,
  socketUrl: `${backendHost}/${process.env.REACT_APP_SOCKET_PATH}`,
  contentApi:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_CONTENT_API
      : '/content', // Proxied, see setupProxy.js

  trsportApi:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_TRSPORT_API
      : '/trsport', // Proxied, see setupProxy.js
  traisApi:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_TRAIS_API
      : '/trais', // Proxied, see setupProxy.js
  bugsnagApiKey: process.env.REACT_APP_BUGSNAG_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_KEY,
  trMediaApiKey: process.env.REACT_APP_TRMEDIA_KEY,
  youtubeApiKey: process.env.REACT_APP_YOUTUBE_KEY,
  youtubePlaylistAtg1: process.env.REACT_APP_YOUTUBE_PLAYLIST_ATG1,
  youtubePlaylistAtg2: process.env.REACT_APP_YOUTUBE_PLAYLIST_ATG2,
  youtubePlaylistSport: process.env.REACT_APP_YOUTUBE_PLAYLIST_SPORT,
  registerUrl:
    'https://www.trmedia.se/user/register?&returnUrl=https://www.jokersystemet.se&cancelUrl=https://www.jokersystemet.se',
  forgotPasswordUrl: 'http://shop.jokersystemet.se/user/password',
  subscribeUrl:
    'https://www.trmedia.se/buy?product_id=1&returnUrl=https://www.jokersystemet.se&cancelUrl=https://www.jokersystemet.se',
  shopUrl: 'https://shop.jokersystemet.se',
  spelvardeShopUrl: 'https://www.spelvarde.se/handla',
  travogatShopUrl: 'https://www.travogat.se/handla',
  affiliateUrl:
    'http://clk.tradedoubler.com/click?p=295103&a=3165491&g=24466066&url={url}',
};

export default config;
