import classnames from 'classnames';
import { bool, oneOf, string } from 'prop-types';
import React from 'react';

import { getText } from 'shared/utils/Text';

const Loader = ({
  visible = false,
  className,
  alwaysShowLabel,
  labelProp,
  backgroundAlpha = 'none',
  fullscreen = false,
  cover = false,
}) => (
  <div
    className={classnames('loader', className, {
      'loader--visible': visible,
      'loader--with-dark-bg': backgroundAlpha === 'dark',
      'loader--with-light-bg': backgroundAlpha === 'light',
      'loader--fullscreen': fullscreen,
      'loader--cover': !fullscreen && cover,
    })}
  >
    <div className="loader__inner">
      <div className="spinner" />
      {(alwaysShowLabel || labelProp) && <h1>{getText(labelProp, '')}</h1>}
    </div>
  </div>
);

Loader.propTypes = {
  visible: bool,
  className: string,
  alwaysShowLabel: bool,
  labelProp: string,
  backgroundAlpha: oneOf(['none', 'dark', 'light']),
  fullscreen: bool,
  cover: bool,
};

export default Loader;
