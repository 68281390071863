import { func, number, string } from 'prop-types';
import React, { Fragment } from 'react';

import * as Text from 'shared/utils/Text';

import Button from '../button/Button';

const ErrorDialog = ({ closeHandler, message, details, logNumber }) => (
  <Fragment>
    {message && <h1 className="dialog-title">{message}</h1>}
    {message && <hr />}
    {details && (
      <p
        className="details"
        dangerouslySetInnerHTML={{ __html: details.replace(/\n/g, '<br/>') }}
      />
    )}
    {details && <hr />}
    {logNumber && <p>{Text.get('dialog-error-log-number', logNumber)}</p>}
    {logNumber && <hr />}
    <ul className="button-list fgrid fgrid--full">
      <li className="fgrid-cell">
        <Button onClick={closeHandler}>
          <span className="sprite dialog-confirm" />
          <span className="label">
            {Text.get('dialog-error-button-confirm')}
          </span>
        </Button>
      </li>
    </ul>
  </Fragment>
);

ErrorDialog.propTypes = {
  closeHandler: func.isRequired,
  message: string.isRequired,
  details: string,
  logNumber: number,
};

export default ErrorDialog;
