import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  UI_SCALE_AUTO,
  UI_SCALE_LARGE,
  UI_SCALE_SMALL,
} from 'shared/constants/AppConstants';
import { ui } from 'shared/redux/selectors';

import PageMeta from './PageMeta';

const mapStateToProps = state => ({
  scale: ui.getScale(state),
});

export default connect(mapStateToProps)(
  class UiScale extends PureComponent {
    static propTypes = {};

    getClassName() {
      const { scale } = this.props;
      switch (scale) {
        case UI_SCALE_AUTO:
          return null;
        case UI_SCALE_LARGE:
          return 'fixed-size-large';
        case UI_SCALE_SMALL:
          return 'fixed-size-small';
        default:
          return null;
      }
    }

    render() {
      const className = this.getClassName();
      return className ? <PageMeta appClassName={className} /> : null;
    }
  },
);
