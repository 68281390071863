import { func, string } from 'prop-types';
import React, { Fragment } from 'react';

import CloseButton from '../button/CloseButton';
import RegisterSubscription from '../login/RegisterSubscription';

const SubscriptionRequiredDialog = ({
  productBodyText,
  productBodyExtra,
  buttonText,
  closeHandler,
}) => (
  <Fragment>
    <CloseButton invert large border={false} onClick={closeHandler} />
    <RegisterSubscription
      productBodyText={productBodyText}
      productBodyExtra={productBodyExtra}
      buttonText={buttonText}
      dialog
    />
  </Fragment>
);

SubscriptionRequiredDialog.propTypes = {
  closeHandler: func.isRequired,
  productBodyText: string,
  productBodyExtra: string,
  buttonText: string,
};

export default SubscriptionRequiredDialog;
