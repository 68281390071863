import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'shared/components/button/Button';
import Close from 'shared/components/icon/Close';

const CloseButton = ({
  border = true,
  className,
  invert = false,
  large = false,
  onClick,
  muted = false,
}) => (
  <Button
    className={classnames(className, 'close-button', {
      'close-button--large': large,
      'close-button--invert': invert,
      'close-button--muted': muted,
      'close-button--border': border,
    })}
    onClick={onClick}
  >
    <Close />
  </Button>
);

CloseButton.propTypes = {
  border: PropTypes.bool,
  invert: PropTypes.bool,
  large: PropTypes.bool,
  muted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
