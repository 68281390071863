// Maps to server enum
export const SESSION_EXPIRED = 'session_expired';
export const LOGGED_IN_TO_OTHER_DEVICE = 'logged_in_other_device';
export const USER_LOGGED_IN = 'user_logged_in';
export const USER_LOGGED_OUT = 'user_logged_out';
// Client only
export const NAVIGATOR_OFFLINE = 'navigator_offline';
export const UPDATE_AVAILABLE = 'update_available';
export const USER_MESSAGE = 'user_message';
export const BET_UPDATE_DELAYED = 'bet_update_delayed';
export const PROMO = 'promo';
