import dotProp from 'dot-prop-immutable';

import * as types from '../actionTypes';

const defaultState = {
  resolved: {},
  errors: {},
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.RECEIVE_INSTRUCTIONS:
      return dotProp.set(
        state,
        `resolved.${action.meta.playlistId}`,
        action.payload,
      );

    case types.RECEIVE_INSTRUCTIONS_ERROR:
      return dotProp.set(
        state,
        `errors.${action.meta.playlistId}`,
        action.payload,
      );

    default:
      return state;
  }
}
