import classnames from 'classnames';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import { bool, func } from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { items } from 'shared/constants/MenuConstants';
import { menuItem } from 'shared/proptypes';
import * as Text from 'shared/utils/Text';

import Button from '../button/Button';
import CheckCircle from '../icon/CheckCircle';
import Update from '../icon/Update';
import AccountItem from './AccountItem';
import BurgerButton from './BurgerButton';

export default class MenuItem extends Component {
  static propTypes = {
    item: menuItem.isRequired,
    disabled: bool.isRequired,
    selected: bool.isRequired,
    clickHandler: func.isRequired,
    popupVisible: bool.isRequired,
    refHandler: func,
  };

  static defaultProps = {
    refHandler: noop,
  };

  onClick = e => {
    const { item, disabled, clickHandler } = this.props;

    if (disabled) {
      e.preventDefault();
      return;
    } else {
      window.scrollTo(0, 0);
      clickHandler(e, item);
    }
  };

  render() {
    const {
      item: { id, path, labelProp },
      disabled,
      selected,
      popupVisible,
      refHandler,
    } = this.props;

    let button;
    const className = classnames('menu-button', {
      disabled,
      active: selected && id !== items.TRMEDIA_ACCOUNT,
    });
    const buttonChildren = (
      <div className="menu-button__inner flex flex--row-center">
        {(id === items.ATG_EXPORT || id === items.SPORT_EXPORT) && (
          <CheckCircle />
        )}
        {id === items.ATG_MANUAL && <Update />}
        {id === items.ACCOUNT ? (
          <AccountItem />
        ) : (
          <span className="title">{labelProp && Text.get(labelProp)}</span>
        )}
      </div>
    );

    if (id === 'burger') {
      button = <BurgerButton onClick={this.onClick} active={popupVisible} />;
    } else {
      if (!isString(path)) {
        button = (
          <Button onClick={this.onClick} className={className}>
            {buttonChildren}
          </Button>
        );
      } else if (path.indexOf('http') === 0) {
        button = (
          <a
            href={path}
            onClick={this.onClick}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
          >
            {buttonChildren}
          </a>
        );
      } else {
        button = (
          <NavLink
            exact={id === items.CALENDAR}
            to={path}
            onClick={this.onClick}
            className={className}
          >
            {buttonChildren}
          </NavLink>
        );
      }
    }

    return (
      <li
        ref={refHandler}
        className={`menu-item menu-item--${id.toLowerCase()}`}
      >
        {button}
      </li>
    );
  }
}
