import dotProp from 'dot-prop-immutable';

import * as types from '../actionTypes';

function transformInfoPages(pages = []) {
  return pages.map(page => {
    const { url_alias } = page;
    return {
      ...page,
      // Remove path portion of generated url alias
      url_alias: url_alias.replace('infosida/jokersystemet/', ''),
    };
  });
}

const defaultState = {
  list: {
    root: [],
    faq: [],
    'frontpage-articles': [],
  },
  loadingList: {
    root: false,
    faq: false,
    'frontpage-articles': false,
  },
  pages: {},
  loadingPages: {},
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.REQUEST_CONTENT_LIST: {
      const { category } = action.meta;
      return dotProp.set(state, `loadingList.${category}`, !action.error);
    }

    case types.RECEIVE_CONTENT_LIST: {
      const { info_pages: infoPages = [] } = action.payload || {};
      const { category } = action.meta;
      let nextState = state;
      nextState = dotProp.set(nextState, `loadingList.${category}`, false);
      nextState = dotProp.set(
        nextState,
        `list.${category}`,
        transformInfoPages(infoPages),
      );
      return nextState;
    }

    case types.RECEIVE_CONTENT_LIST_ERROR: {
      const { category } = action.meta;
      return dotProp.set(state, `loadingList.${category}`, false);
    }

    case types.REQUEST_CONTENT_PAGE: {
      const { pageId } = action.meta;
      return dotProp.set(state, `loadingPages.${pageId}`, !action.error);
    }

    case types.RECEIVE_CONTENT_PAGE: {
      const { pageId } = action.meta;
      return {
        ...state,
        pages: dotProp.set(state.pages, pageId, action.payload),
        loadingPages: dotProp.set(state.loadingPages, pageId, false),
      };
    }

    case types.RECEIVE_CONTENT_PAGE_ERROR: {
      const { pageId } = action.meta;
      return dotProp.set(state, `loadingPages.${pageId}`, false);
    }

    default:
      return state;
  }
}
