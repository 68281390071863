import { arrayOf, object, shape, string } from 'prop-types';
import React, { Component } from 'react';

import { getText } from 'shared/utils/Text';

import ErrorInfo from '../ErrorInfo';
import Loader from '../loader/Loader';
import InstructionsItem from './InstructionsItem';

export default class InstructionsList extends Component {
  static propTypes = {
    heading: string.isRequired,
    items: arrayOf(
      shape({
        title: string.isRequired,
        description: string.isRequired,
        thumbnails: object.isRequired,
        resourceId: shape({
          videoId: string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
    error: object,
  };

  state = {};

  onPlayClick = e => {
    const videoId = e.currentTarget.getAttribute('data-id');
    this.setState({
      [videoId]: true,
    });
  };

  render() {
    const { heading, items, error } = this.props;
    return (
      <div className="instructions">
        <h2>{heading}</h2>
        <Loader visible={items.length === 0 && error == null} />
        {error != null && (
          <ErrorInfo
            error={error}
            heading={getText('instructions-error-title')}
          />
        )}
        <ul>
          {items.map(
            ({ resourceId: { videoId }, thumbnails, title, description }) => (
              <InstructionsItem
                key={videoId}
                videoId={videoId}
                thumbnail={{
                  ...thumbnails.maxres,
                }}
                title={title}
                description={description}
                playing={this.state[videoId] === true}
                onPlayClick={this.onPlayClick}
              />
            ),
          )}
        </ul>
      </div>
    );
  }
}
