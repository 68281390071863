import config from 'config';
import { string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InstructionsList from 'shared/components/instructions/InstructionsList';
import { fetchInstructions } from 'shared/redux/actions';
import { instructions } from 'shared/redux/selectors';
import { getText } from 'shared/utils/Text';

import ContentPage from './ContentPage';
import withContentMenu from './withContentMenu';

const mapStateToProps = state => ({
  atg1Items: instructions.getPlaylistItems(state, {
    playlistId: config.youtubePlaylistAtg1,
  }),
  atg2Items: instructions.getPlaylistItems(state, {
    playlistId: config.youtubePlaylistAtg2,
  }),
  sportItems: instructions.getPlaylistItems(state, {
    playlistId: config.youtubePlaylistSport,
  }),
  atg1Error: instructions.getPlaylistError(state, {
    playlistId: config.youtubePlaylistAtg1,
  }),
  atg2Error: instructions.getPlaylistError(state, {
    playlistId: config.youtubePlaylistAtg2,
  }),
  sportError: instructions.getPlaylistError(state, {
    playlistId: config.youtubePlaylistSport,
  }),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchInstructions }, dispatch);

export default withContentMenu(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class InstructionsPage extends Component {
      static propTypes = {
        pageId: string.isRequired,
        pageTitle: string.isRequired,
      };

      componentDidMount() {
        this.props.fetchInstructions(config.youtubePlaylistAtg1);
        this.props.fetchInstructions(config.youtubePlaylistAtg2);
        this.props.fetchInstructions(config.youtubePlaylistSport);
      }

      render() {
        const {
          pageId,
          pageTitle,
          atg1Items,
          atg2Items,
          sportItems,
          atg1Error,
          atg2Error,
          sportError,
        } = this.props;

        // Combine atg playlists when both have loaded.
        // We want the second, newer playlist, first.
        const atgItems =
          atg1Items.length > 0 && atg2Items.length > 0
            ? [...atg2Items, ...atg1Items]
            : [];

        return (
          <ContentPage pageId={pageId} pageTitle={pageTitle}>
            <InstructionsList
              heading={getText('instructions-sport-title')}
              items={sportItems}
              error={sportError}
            />
            <InstructionsList
              heading={getText('instructions-atg-title')}
              items={atgItems}
              error={atg1Error || atg2Error}
            />
          </ContentPage>
        );
      }
    },
  ),
);
