import { connect } from 'react-redux';

import SubscriptionsPage from 'shared/components/SubscriptionsPage';
import { user } from 'shared/redux/selectors';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
});

export default connect(mapStateToProps)(SubscriptionsPage);
