import { string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchContentPage } from 'shared/redux/actions';
import { content } from 'shared/redux/selectors';
import { getDisplayName } from 'shared/utils';

const mapStateToProps = (state, ownProps) => ({
  page: content.getPage(state, ownProps),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchContentPage }, dispatch);

const withCMSPage = WrappedComponent => {
  class WithCMSPage extends Component {
    static propTypes = {
      pageId: string.isRequired,
    };

    static displayName = `WithCMSPage(${getDisplayName(WrappedComponent)})`;

    componentDidMount() {
      const { pageId, fetchContentPage } = this.props;
      fetchContentPage(pageId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { pageId, fetchContentPage } = nextProps;
      if (pageId !== this.props.pageId) {
        fetchContentPage(pageId);
      }
    }

    render() {
      const { page } = this.props;
      const {
        body: { und: [{ safe_value: pageBody } = {}] = [] } = {},
        field_intro: { und: [{ safe_value: pageIntro } = {}] = [] } = {},
      } = page || {};

      return (
        <WrappedComponent
          {...this.props}
          pageBody={pageBody}
          pageIntro={pageIntro}
        />
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(WithCMSPage);
};

export default withCMSPage;
