export const TIMEZONE_LABEL = 'CET';

export const UI_SCALE_AUTO = 'auto';
export const UI_SCALE_LARGE = 'large';
export const UI_SCALE_SMALL = 'small';

export const ATG_PRODUCTS = [
  'V75',
  'V86',
  'GS75',
  'V64',
  'V65',
  'V5',
  'V4',
  'V3',
  'T',
  'DD',
  'LD',
];

export const TOPPTIPSET_PRODUCTS = [
  'Topptipset',
  'TopptipsetEuropa',
  'TopptipsetStryk',
];

export const SPORT_PRODUCTS = [
  'Stryktipset',
  'Europatipset',
  ...TOPPTIPSET_PRODUCTS,
  'PowerPlay',
  'WmTipset',
];
