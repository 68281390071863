import { bool, func, oneOf } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'shared/components/button/Button';
import CloseButton from 'shared/components/button/CloseButton';
import HTMLContent from 'shared/components/HTMLContent';
import CheckboxInput from 'shared/components/input/CheckboxInput';
import SelectInput from 'shared/components/input/SelectInput';
import {
  UI_SCALE_AUTO,
  UI_SCALE_LARGE,
  UI_SCALE_SMALL,
} from 'shared/constants/AppConstants';
import {
  changeUiScale,
  fetchSettings,
  updateSettings,
} from 'shared/redux/actions';
import { settings, ui } from 'shared/redux/selectors';
import * as Text from 'shared/utils/Text';

import Loader from '../loader/Loader';

const rankingOptions = ['mark-percent', 'start-number'];
const gridScaleOptions = [UI_SCALE_AUTO, UI_SCALE_SMALL, UI_SCALE_LARGE];
const spareTypeOptions = ['NEXT_RANKED', 'BEST_RANKED', 'MARK_PERCENT'];

const mapStateToProps = state => ({
  defaultRanking: settings.defaultRanking(state),
  manualMode: settings.isManualMode(state),
  showMarkPercent: settings.showMarkPercent(state),
  showOdds: settings.showOdds(state),
  showRankingResults: settings.showRankingResults(state),
  showBalance: settings.showBalance(state),
  showWagon: settings.showWagon(state),
  gridScale: ui.getScale(state),
  spareType: settings.spareType(state),
  v6: settings.v6(state),
  v7: settings.v7(state),
  hasSettings: settings.getSettings(state) != null,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchSettings, updateSettings, changeUiScale },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class SettingsDialog extends Component {
    static propTypes = {
      closeHandler: func.isRequired,
      updateSettings: func.isRequired,
      defaultRanking: oneOf(rankingOptions),
      manualMode: bool.isRequired,
      showMarkPercent: bool.isRequired,
      showOdds: bool.isRequired,
      showRankingResults: bool.isRequired,
      showBalance: bool.isRequired,
      showWagon: bool.isRequired,
      gridScale: oneOf(gridScaleOptions).isRequired,
      spareType: oneOf(spareTypeOptions).isRequired,
      v6: bool.isRequired,
      v7: bool.isRequired,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (!nextProps.hasSettings) {
        return {};
      }

      return {
        defaultRanking: prevState.hasOwnProperty('defaultRanking')
          ? prevState.defaultRanking
          : nextProps.defaultRanking,
        manualMode: prevState.hasOwnProperty('manualMode')
          ? prevState.manualMode
          : nextProps.manualMode,
        showMarkPercent: prevState.hasOwnProperty('showMarkPercent')
          ? prevState.showMarkPercent
          : nextProps.showMarkPercent,
        showOdds: prevState.hasOwnProperty('showOdds')
          ? prevState.showOdds
          : nextProps.showOdds,
        showRankingResults: prevState.hasOwnProperty('showRankingResults')
          ? prevState.showRankingResults
          : nextProps.showRankingResults,
        showBalance: prevState.hasOwnProperty('showBalance')
          ? prevState.showBalance
          : nextProps.showBalance,
        showWagon: prevState.hasOwnProperty('showWagon')
          ? prevState.showWagon
          : nextProps.showWagon,
        gridScale: prevState.hasOwnProperty('gridScale')
          ? prevState.gridScale
          : nextProps.gridScale,
        spareType: prevState.hasOwnProperty('spareType')
          ? prevState.spareType
          : nextProps.spareType,
        v6: prevState.hasOwnProperty('v6') ? prevState.v6 : nextProps.v6,
        v7: prevState.hasOwnProperty('v7') ? prevState.v7 : nextProps.v7,
      };
    }

    state = {};

    componentDidMount() {
      this.props.fetchSettings();
    }

    onManualModeChange = () => {
      this.setState(state => ({ manualMode: !state.manualMode }));
    };

    onDefaultRankingChange = e => {
      const value = e.currentTarget.value;
      this.setState(() => ({ defaultRanking: value }));
    };

    onshowMarkPercentChange = () => {
      this.setState(state => ({ showMarkPercent: !state.showMarkPercent }));
    };

    onshowOddsChange = () => {
      this.setState(state => ({ showOdds: !state.showOdds }));
    };

    onshowBalanceChange = () => {
      this.setState(state => ({ showBalance: !state.showBalance }));
    };

    onshowWagonChange = () => {
      this.setState(state => ({ showWagon: !state.showWagon }));
    };

    onshowRankingResultsChange = () => {
      this.setState(state => ({
        showRankingResults: !state.showRankingResults,
      }));
    };

    onGridScaleChange = e => {
      const value = e.currentTarget.value;
      this.setState(() => ({ gridScale: value }));
    };

    onSpareTypeChange = e => {
      const value = e.currentTarget.value;
      this.setState(() => ({ spareType: value }));
    };

    onv6Change = () => {
      this.setState(state => ({
        v6: !state.v6,
      }));
    };

    onv7Change = () => {
      this.setState(state => ({
        v7: !state.v7,
      }));
    };

    onOkClick = () => {
      const { gridScale, updateSettings, changeUiScale, closeHandler } =
        this.props;
      if (gridScale !== this.state.gridScale) {
        changeUiScale(this.state.gridScale);
      }

      if (
        [
          'defaultRanking',
          'manualMode',
          'showMarkPercent',
          'showOdds',
          'showRankingResults',
          'showBalance',
          'showWagon',
          'spareType',
          'v6',
          'v7',
        ].some(p => this.state[p] !== this.props[p])
      ) {
        const {
          defaultRanking,
          manualMode,
          showMarkPercent,
          showOdds,
          showRankingResults,
          showBalance,
          showWagon,
          spareType,
          v6,
          v7,
        } = this.state;
        updateSettings({
          defaultRanking,
          manualMode,
          showMarkPercent,
          showOdds,
          showRankingResults,
          showBalance,
          showWagon,
          spareType,
          v6,
          v7,
        });
      }

      closeHandler();
    };

    render() {
      const { closeHandler, hasSettings } = this.props;
      const {
        defaultRanking,
        manualMode = false,
        showMarkPercent = false,
        showOdds = false,
        showRankingResults = false,
        showBalance = false,
        showWagon = false,
        gridScale,
        spareType,
        v6 = false,
        v7 = false,
      } = this.state;

      return (
        <Fragment>
          <Loader visible={!hasSettings} backgroundAlpha="light" cover />

          <h1 className="dialog-title">{Text.get('dialog-settings-title')}</h1>
          <CloseButton onClick={closeHandler} />
          <hr />

          <form>
            <div className="fgrid fgrid--dialog-form fgrid-dialog-form-wide-labels">
              <HTMLContent
                content={Text.get('dialog-settings-manual-mode-desc')}
              />
              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-manual-mode')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={manualMode}
                  onChange={this.onManualModeChange}
                  colorFill
                />
              </div>
            </div>

            <hr />

            <HTMLContent
              content={Text.get('dialog-settings-default-ranking-desc')}
            />
            <div className="fgrid fgrid--dialog-form fgrid-dialog-form-wide-labels">
              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-default-ranking')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <SelectInput
                  disabled={!hasSettings}
                  value={defaultRanking}
                  onChange={this.onDefaultRankingChange}
                >
                  {rankingOptions.map(option => (
                    <option key={option} value={option}>
                      {Text.get(`dialog-settings-default-ranking-${option}`)}
                    </option>
                  ))}
                </SelectInput>
              </div>
            </div>

            <hr />

            <div className="fgrid fgrid--dialog-form fgrid-dialog-form-wide-labels">
              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-show-mark-precent')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={showMarkPercent}
                  onChange={this.onshowMarkPercentChange}
                  colorFill
                />
              </div>

              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-show-ranking-results')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={showRankingResults}
                  onChange={this.onshowRankingResultsChange}
                  colorFill
                />
              </div>

              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-show-odds')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={showOdds}
                  onChange={this.onshowOddsChange}
                  colorFill
                />
              </div>

              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-show-balance-info')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={showBalance}
                  onChange={this.onshowBalanceChange}
                  colorFill
                />
              </div>

              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-show-wagon-info')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={showWagon}
                  onChange={this.onshowWagonChange}
                  colorFill
                />
              </div>
            </div>

            <hr />

            <HTMLContent content={Text.get('dialog-settings-defaults-desc')} />
            <div className="fgrid fgrid--dialog-form fgrid-dialog-form-wide-labels">
              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-bet-config-spare-type')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <SelectInput
                  disabled={!hasSettings}
                  value={spareType}
                  onChange={this.onSpareTypeChange}
                >
                  {spareTypeOptions.map(option => (
                    <option key={option} value={option}>
                      {Text.get(
                        `dialog-bet-config-spare-type-${option.toLowerCase()}`,
                      )}
                    </option>
                  ))}
                </SelectInput>
              </div>

              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-bet-config-v6')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  disabled={!hasSettings}
                  checked={v6}
                  onChange={this.onv6Change}
                  colorFill
                />
              </div>

              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-bet-config-gs7')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <CheckboxInput
                  checked={v7}
                  onChange={this.onv7Change}
                  colorFill
                />
              </div>
            </div>

            <hr />

            <div className="fgrid fgrid--dialog-form fgrid-dialog-form-wide-labels">
              <HTMLContent
                content={Text.get('dialog-settings-ui-scale-desc')}
              />
              <div className="fgrid-cell fgrid-cell-label">
                {Text.get('dialog-settings-ui-scale')}
              </div>
              <div className="fgrid-cell fgrid-cell-field">
                <SelectInput
                  disabled={!hasSettings}
                  value={gridScale}
                  onChange={this.onGridScaleChange}
                >
                  {gridScaleOptions.map(option => (
                    <option key={option} value={option}>
                      {Text.get(`dialog-settings-${option}`)}
                    </option>
                  ))}
                </SelectInput>
              </div>
            </div>

            <hr />
          </form>

          <ul className="button-list fgrid fgrid--1of2">
            <li className="fgrid-cell positive">
              <Button onClick={this.onOkClick} disabled={!hasSettings}>
                <span className="sprite dialog-confirm" />
                <span className="label">
                  {Text.get('dialog-confirm-confirm')}
                </span>
              </Button>
            </li>
            <li className="fgrid-cell negative">
              <Button onClick={closeHandler}>
                <span className="sprite dialog-cancel" />
                <span className="label">
                  {Text.get('dialog-confirm-cancel')}
                </span>
              </Button>
            </li>
          </ul>
        </Fragment>
      );
    }
  },
);
