import classnames from 'classnames';
import { bool, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { getText } from 'shared/utils/Text';

const Register = ({
  productHeader,
  productBody,
  productBodyExtra,
  buttonText,
  buttonUrl,
  dialog,
}) => (
  <div
    className={classnames(
      'login-container login-container--background register',
      {
        'login-container--border': !dialog,
        'login-container--dialog': dialog,
      },
    )}
  >
    <h1 className="product-heading">
      <span className="product-heading__name">
        {getText('title-app').toUpperCase()}
      </span>
      <span className="product-heading__color">{productHeader}</span>
    </h1>
    <p className={productBodyExtra && 'product-body--bold'}>{productBody}</p>
    {productBodyExtra && <p>{productBodyExtra}</p>}
    <Link to={buttonUrl} className="register-button">
      {buttonText}
    </Link>
  </div>
);

Register.propTypes = {
  productHeader: string.isRequired,
  productBody: string.isRequired,
  buttonText: string.isRequired,
  buttonUrl: string.isRequired,
  dialog: bool,
};

export default Register;
