import { string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FaqList from 'shared/components/content/FaqList';
import { fetchFaqList } from 'shared/redux/actions';
import { content } from 'shared/redux/selectors';

import ContentPage from './ContentPage';

const mapStateToProps = state => ({
  faqList: content.getList(state, { category: 'faq' }),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFaqList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class FaqPage extends Component {
    static propTypes = {
      pageId: string.isRequired,
      pageTitle: string.isRequired,
    };

    componentDidMount() {
      this.props.fetchFaqList();
    }

    render() {
      const {
        pageId,
        pageTitle,
        faqList,
        match: { url },
        history,
      } = this.props;

      return (
        <ContentPage pageId={pageId} pageTitle={pageTitle}>
          <FaqList items={faqList} baseUrl={url} history={history} />
        </ContentPage>
      );
    }
  },
);
