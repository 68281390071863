import PropTypes from 'prop-types';
import React from 'react';

import CloseButton from 'shared/components/button/CloseButton';
import HTMLContent from 'shared/components/HTMLContent';

const HelpDialog = ({ closeHandler, title, body }) => (
  <>
    <CloseButton onClick={closeHandler} />

    {title && (
      <div className="dialog-help-header">
        <img alt="" className="dialog-help-icon" src="/images/help.svg" />
        <h2>{title}</h2>
      </div>
    )}
    <HTMLContent className="text-body" content={body} />
  </>
);

HelpDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default HelpDialog;
