import { fnv32a } from '@trmediaab/zebra-utils';
import dotProp from 'dot-prop-immutable';
import partition from 'lodash/partition';

import { UI_SCALE_AUTO } from 'shared/constants/AppConstants';
import {
  BET_UPDATE_DELAYED,
  LOGGED_IN_TO_OTHER_DEVICE,
  SESSION_EXPIRED,
  USER_MESSAGE,
} from 'shared/constants/MessageTypes';
import {
  ATG_DELAYED_BET,
  MESSAGES_PRIVATE,
  MESSAGES_PUBLIC,
} from 'shared/constants/StompKind';

import * as types from '../actionTypes';

const defaultState = {
  documentHidden: false,
  loader: {
    visible: true,
    labelProp: null,
  },
  scale: UI_SCALE_AUTO,
  siteMessages: [],
  dismissedSiteMessagesByHash: {
    local: [],
    persist: [],
  },
};

function getMessageHash(message) {
  const { id, clientId, message: text } = message;
  return id ? fnv32a(id + text) : clientId;
}

const isMatchingMessages = (newMessage, oldMessage) =>
  newMessage.id === oldMessage.id ||
  (newMessage.messageType !== USER_MESSAGE &&
    newMessage.messageType === oldMessage.messageType);

const isUpdatedMessage = (newMessage, oldMessage, state) =>
  isMatchingMessages(newMessage, oldMessage) &&
  !state.dismissedSiteMessagesByHash.local.includes(oldMessage.hash) &&
  !state.dismissedSiteMessagesByHash.persist.includes(oldMessage.hash);

function addSiteMessages(state, messageType, messages) {
  const messagesArray = Array.isArray(messages) ? messages : [messages];

  const [updatedMessages, newMessages] = partition(messagesArray, message =>
    state.siteMessages.some(oldMessage =>
      isUpdatedMessage(message, oldMessage, state),
    ),
  );

  return dotProp.set(state, 'siteMessages', oldMessages => [
    ...newMessages.map(message => ({
      ...message,
      messageType,
      hash: getMessageHash(message),
    })),
    ...oldMessages.map(oldMessage => {
      const updatedMessage = updatedMessages.find(message =>
        isMatchingMessages(message, oldMessage),
      );
      const clientId = oldMessage.clientId;
      return {
        ...oldMessage,
        ...updatedMessage,
        clientId,
      };
    }),
  ]);
}

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.UPDATE_PAGE_VISIBILITY:
      return dotProp.set(state, 'documentHidden', action.payload);

    case types.CHANGE_UI_SCALE:
      return dotProp.set(state, 'scale', action.payload);

    case types.RECEIVE_PROMO_MESSAGE: {
      if (action.payload.results.length > 0) {
        const { headline, links } = action.payload.results[0];
        return addSiteMessages(state, 'promo', {
          message: headline,
          link: links?.[0],
        });
      } else {
        return state;
      }
    }

    case types.RECEIVE_PROMO_ERROR:
      return dotProp.set(state, 'promo', action.payload);

    case types.SHOW_LOADER:
      return dotProp.set(state, 'loader', {
        visible: true,
        labelProp: action.payload,
      });

    case types.HIDE_LOADER:
      return dotProp.set(state, 'loader', {
        visible: false,
        labelProp: null,
      });

    case types.ADD_SITE_MESSAGE: {
      const { messageType, message } = action.payload;
      return addSiteMessages(state, messageType, [message]);
    }

    case types.STOMP_RECEIVE_DATA: {
      const { kind } = action.payload;
      const { clientId } = action.meta;

      switch (kind) {
        case ATG_DELAYED_BET: {
          const {
            data: { progress },
          } = action.payload;
          return addSiteMessages(state, BET_UPDATE_DELAYED, {
            progress: !Number.isNaN(progress) ? progress : 100,
            clientId,
          });
        }
        case MESSAGES_PUBLIC: {
          const {
            data: { message, messages },
          } = action.payload;

          if (message != null) {
            return addSiteMessages(state, USER_MESSAGE, message);
          }
          if (Array.isArray(messages) && messages.length > 0) {
            return addSiteMessages(state, USER_MESSAGE, messages);
          }

          break;
        }
        case MESSAGES_PRIVATE: {
          const {
            data: { messageType },
          } = action.payload;
          if (
            [SESSION_EXPIRED, LOGGED_IN_TO_OTHER_DEVICE].includes(messageType)
          ) {
            return addSiteMessages(state, messageType, { clientId });
          }

          break;
        }
        default:
        // Do nothing
      }
      return state;
    }

    case types.DISMISS_SITE_MESSAGE_BY_SERVER_ID:
    case types.DISMISS_SITE_MESSAGE_BY_CLIENT_ID: {
      const path = `dismissedSiteMessagesByHash.${
        action.type === types.DISMISS_SITE_MESSAGE_BY_SERVER_ID
          ? 'persist'
          : 'local'
      }`;
      return dotProp.set(state, path, dismissed => [
        ...dismissed,
        action.payload.hash,
      ]);
    }

    case types.LOCATION_PATHNAME_CHANGE:
    case types.DISMISS_SITE_MESSAGE_BY_TYPE: {
      // Dismiss bet update delayed on path change
      const messageType =
        action.type === types.LOCATION_PATHNAME_CHANGE
          ? BET_UPDATE_DELAYED
          : action.payload;
      const path = `dismissedSiteMessagesByHash.${
        messageType === USER_MESSAGE ? 'persist' : 'local'
      }`;

      return dotProp.set(state, path, dismissed => [
        ...dismissed,
        ...state.siteMessages
          .filter(message => message.messageType === messageType)
          .map(message => message.hash),
      ]);
    }

    default:
      return state;
  }
}
