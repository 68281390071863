import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import reject from 'lodash/reject';

import * as dialogTypes from 'shared/constants/DialogTypes';

import * as types from '../actionTypes';

const isDuplicate = (prev, next) => {
  if (!prev) {
    return false;
  }

  const { type: prevType, props: prevProps = {} } = prev;
  const { type: nextType, props: nextProps = {} } = next;

  if (prevType !== nextType) {
    return false;
  }

  return isEqual(prevProps, nextProps);
};

export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case types.SHOW_DIALOG: {
      // Prevent showing duplicate dialogs
      if (isDuplicate(last(state), action.payload)) {
        return state;
      }

      const { type, props = {} } = action.payload;
      const { key } = action.meta;

      return [...state, { type, props, key }];
    }

    case types.POP_DIALOG: {
      const key = action.payload;
      if (key) {
        return reject(state, dialog => dialog.key === key && !dialog.locked);
      } else if (state.length > 0 && !state[state.length - 1].locked) {
        return state.slice(0, -1);
      } else {
        return state;
      }
    }

    case types.POP_DIALOG_BY_TYPE: {
      const dialogType = action.payload;
      return state.filter(dialog => dialog.type !== dialogType);
    }

    case types.TOGGLE_LOCK_DIALOG: {
      const { key, locked } = action.payload;
      return state.map(dialog =>
        dialog.key === key ? { ...dialog, locked } : dialog,
      );
    }

    case types.LOCATION_PATHNAME_CHANGE: {
      return state.filter(
        dialog =>
          dialog.type === dialogTypes.ERROR ||
          dialog.type === dialogTypes.ALERT,
      );
    }
    default:
      return state;
  }
}
