import { string } from 'prop-types';
import React, { Fragment } from 'react';

import withCMSPage from 'shared/containers/withCMSPage';

import HTMLContent from '../HTMLContent';
import PageMeta from '../PageMeta';
import Editorial from './Editorial';

const FaqItem = ({ pageBody, pageTitle }) => (
  <Fragment>
    <PageMeta title={pageTitle} />
    <Editorial>
      <HTMLContent content={pageBody} />
    </Editorial>
  </Fragment>
);

FaqItem.propTypes = {
  pageBody: string,
  pageTitle: string.isRequired,
};

export default withCMSPage(FaqItem);
