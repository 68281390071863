import { string } from 'prop-types';
import React from 'react';

const reLink = /<a /gi;

// Insert target and rel attributes to all links
const transformHtml = html =>
  html != null && html.length > 0
    ? html.replace(reLink, `<a target="_blank" rel="noopener noreferrer" `)
    : html;

const HTMLContent = ({ content, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: transformHtml(content) }}
  />
);

HTMLContent.propTypes = {
  content: string,
  className: string,
};

export default HTMLContent;
