import React from 'react';

import Icon from './Icon';

const ArrowForward = ({ className }) => (
  <Icon name="arrowforward" className={className} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
  </Icon>
);

// https://material.io/icons/#ic_arrow_forward
export default ArrowForward;
