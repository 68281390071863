import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';
import { settings, user } from '../selectors';

export function fetchSettings() {
  return {
    [RSAA]: {
      bailout: state =>
        !user.isLoggedIn(state) ||
        !user.hasSubscription(state) ||
        settings.getSettings(state) != null ||
        settings.isLoading(state),
      endpoint: 'user/fetchSettings',
      types: [
        {
          type: types.REQUEST_SETTINGS,
          payload: (action, state) => settings.getSettings(state),
        },
        types.RECEIVE_SETTINGS,
        types.RECEIVE_SETTINGS_ERROR,
      ],
    },
  };
}

export function updateSettings(newSettings) {
  return {
    [RSAA]: {
      bailout: state => settings.isLoading(state),
      endpoint: 'user/updateSettings',
      method: 'POST',
      body: state =>
        JSON.stringify({ ...newSettings, id: settings.getId(state) }),
      types: [
        { type: types.REQUEST_UPDATE_SETTINGS, payload: newSettings },
        types.RECEIVE_UPDATE_SETTINGS,
        types.RECEIVE_UPDATE_SETTINGS_ERROR,
      ],
    },
  };
}
