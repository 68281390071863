import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';
import { content } from '../selectors';

const fetchList = category => ({
  [RSAA]: {
    bailout: state => !content.shouldFetchList(state, { category }),
    endpoint: `jokersystemet.json${
      category !== 'root' ? `?category=${category}` : ''
    }`,
    types: [
      { type: types.REQUEST_CONTENT_LIST, meta: { category } },
      { type: types.RECEIVE_CONTENT_LIST, meta: { category } },
      {
        type: types.RECEIVE_CONTENT_LIST_ERROR,
        meta: { category, errorType: 'discrete' },
      },
    ],
  },
});

export const fetchContentList = () => fetchList('root');
export const fetchFaqList = () => fetchList('faq');
export const fetchFrontPageList = () => fetchList('frontpage-articles');

export function fetchContentPage(pageId) {
  return {
    [RSAA]: {
      bailout: state => !content.shouldFetchPage(state, { pageId }),
      endpoint: `jokersystemet/${pageId}.json`,
      types: [
        {
          type: types.REQUEST_CONTENT_PAGE,
          meta: { pageId },
        },
        {
          type: types.RECEIVE_CONTENT_PAGE,
          meta: { pageId },
        },
        {
          type: types.RECEIVE_CONTENT_PAGE_ERROR,
          meta: { pageId, errorType: 'discrete' },
        },
      ],
    },
  };
}
