import React from 'react';

import Icon from './Icon';

const Checkmark = ({ className }) => (
  <Icon
    name="checkmark"
    className={className}
    viewBox="0 0 24 24"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path
      d="M19.344 6.037l-7.539 11.847-.174.027-6.771-5.643 1.28-1.536 5.127 4.272 6.389-10.041 1.688 1.074z"
      fillRule="nonzero"
    />
  </Icon>
);

export default Checkmark;
