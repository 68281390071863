import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { history } from 'react-router-prop-types';

import { getText } from 'shared/utils/Text';

import ContentWrapper from '../layout/ContentWrapper';
import FaqItem from './FaqItem';

const FaqList = ({ items, baseUrl, history }) => (
  <ContentWrapper columnWidth="narrow" className="faq-list">
    <h2>{getText('faq-list-header')}</h2>
    <ul>
      {items.map(({ id, url_alias: urlAlias, title }) => (
        <li className="item" key={id}>
          <NavLink
            onClick={e => {
              if (e.target.href === window.location.href) {
                history.push(baseUrl);
                e.preventDefault();
              }
            }}
            to={`${baseUrl}/${urlAlias}`}
            className="item-link match-text"
          >
            {title}
          </NavLink>
          <Route
            path={`${baseUrl}/${urlAlias}`}
            exact
            render={routeProps => (
              <FaqItem {...routeProps} pageId={id} pageTitle={title} />
            )}
          />
        </li>
      ))}
    </ul>
  </ContentWrapper>
);

FaqList.propTypes = {
  items: arrayOf(
    shape({
      id: string,
      title: string,
    }).isRequired,
  ),
  baseUrl: string.isRequired,
  history: history.isRequired,
};

export default FaqList;
