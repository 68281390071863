import classnames from 'classnames';
import { arrayOf, func, number, shape, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ExternalLink from 'shared/components/ExternalLink';
import * as types from 'shared/constants/MessageTypes';
import { getText } from 'shared/utils/Text';

import Button from './button/Button';
import CloseButton from './button/CloseButton';
import Editorial from './content/Editorial';
import HTMLContent from './HTMLContent';
import Info from './icon/Info';
import Warning from './icon/Warning';
import ContentWrapper from './layout/ContentWrapper';
import ProgressBar from './ProgressBar';

const renderMessageText = ({ messageType, message: text }) => {
  if (messageType === types.SESSION_EXPIRED) {
    return getText('site-message-session');
  }

  if (messageType === types.LOGGED_IN_TO_OTHER_DEVICE) {
    return getText('site-message-other-device');
  }

  if (messageType === types.NAVIGATOR_OFFLINE) {
    return getText('site-message-network');
  }

  if (messageType === types.UPDATE_AVAILABLE) {
    return getText('site-message-update');
  }

  if (messageType === types.BET_UPDATE_DELAYED) {
    return (
      <HTMLContent
        className="html-message"
        content={getText('site-message-betdelayed')}
      />
    );
  }

  if (messageType === types.USER_MESSAGE) {
    return <HTMLContent className="html-message" content={text} />;
  }

  if (messageType === types.PROMO) {
    return text;
  }

  return text || getText('error-message-generic');
};

const renderMessageLink = ({ messageType, ...messageProps }, onClose) => {
  if (
    messageType === types.SESSION_EXPIRED ||
    messageType === types.LOGGED_IN_TO_OTHER_DEVICE
  ) {
    return (
      <Link to="/login" onClick={() => onClose()} className="message-link">
        {getText('login-button')}
      </Link>
    );
  }

  if (messageType === types.UPDATE_AVAILABLE) {
    const { swRegistration } = messageProps;
    const onClick = e => {
      try {
        e.currentTarget.disabled = true;
        // Tell new service worker to activate immediately
        swRegistration.waiting.postMessage('force-activate', '*');
      } catch {
        window.location.reload();
      }
    };
    return (
      <Button onClick={onClick} className="message-link">
        {getText('site-message-button-reload')}
      </Button>
    );
  }

  if (messageType === types.USER_MESSAGE) {
    return (
      <Button onClick={onClose} className="message-link">
        {getText('site-message-button-close')}
      </Button>
    );
  }

  if (messageType === types.PROMO && messageProps.link != null) {
    return (
      <ExternalLink url={messageProps.link.url} className="message-link">
        {messageProps.link.text}
      </ExternalLink>
    );
  }

  return null;
};

const renderMessageIcon = ({ messageType }) => {
  if (messageType === types.PROMO) {
    return null;
  }

  if (
    messageType === types.USER_MESSAGE ||
    messageType === types.UPDATE_AVAILABLE ||
    messageType === types.BET_UPDATE_DELAYED
  ) {
    return (
      <div className="icon">
        <Info />
      </div>
    );
  }

  return (
    <div className="icon">
      <Warning />
    </div>
  );
};

const renderMessageProgress = ({ progress }) => {
  if (progress != null) {
    return <ProgressBar progress={progress} />;
  }
  return null;
};

const SiteMessages = ({ messages, onClose }) => (
  <TransitionGroup className="site-messages" exit={false}>
    {messages.map(message => {
      const onCloseBound = onClose.bind(null, message);
      return (
        <CSSTransition
          key={message.id || message.clientId || message.message}
          classNames="item"
          timeout={1000}
        >
          <div
            className={classnames('item', {
              'item--negative':
                message &&
                (message.messageType === types.SESSION_EXPIRED ||
                  message.messageType === types.LOGGED_IN_TO_OTHER_DEVICE ||
                  message.messageType === types.NAVIGATOR_OFFLINE),
              'item--neutral-orange':
                message && message.messageType === types.USER_MESSAGE,
              'item--neutral-blue':
                message &&
                (message.messageType === types.BET_UPDATE_DELAYED ||
                  message.messageType === types.PROMO),
              'item--positive':
                message && message.messageType === types.UPDATE_AVAILABLE,
            })}
          >
            {renderMessageProgress(message)}

            <ContentWrapper>
              <div className="item__content">
                <div className="flex">
                  {renderMessageIcon(message)}
                  <div className="text">
                    <Editorial smallText>
                      {renderMessageText(message)}{' '}
                      {renderMessageLink(message, onCloseBound)}
                    </Editorial>
                  </div>
                </div>
                {message.messageType !== types.UPDATE_AVAILABLE && (
                  <div className="buttons">
                    <CloseButton onClick={onCloseBound} large border={false} />
                  </div>
                )}
              </div>
            </ContentWrapper>
          </div>
        </CSSTransition>
      );
    })}
  </TransitionGroup>
);

SiteMessages.propTypes = {
  messages: arrayOf(
    shape({
      id: number,
      clientId: string,
      messageType: string.isRequired,
      message: string,
      progress: number,
    }),
  ),
  onClose: func.isRequired,
};

export default SiteMessages;
