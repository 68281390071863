import { bool, node, oneOf } from 'prop-types';
import React from 'react';

import ContentWrapper from './ContentWrapper';

const alignClassNames = {
  edge: 'align-to-edge',
  menu: 'align-to-menu',
  menuAndEdge: 'align-to-menu-edge',
  contentStart: 'align-to-content-start',
};

const PageBody = ({
  children,
  fullWidth = false,
  alignTo = 'contentStart',
}) => (
  <main className={`page-body ${alignClassNames[alignTo]}`} role="main">
    {fullWidth ? children : <ContentWrapper>{children}</ContentWrapper>}
  </main>
);

PageBody.propTypes = {
  children: node.isRequired,
  fullWidth: bool,
  alignTo: oneOf(['edge', 'menu', 'menuAndEdge', 'contentStart']),
};

export default PageBody;
