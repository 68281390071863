import classnames from 'classnames';
import config from 'config';
import { func, string } from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { getText } from 'shared/utils/Text';

import TextInput from '../input/TextInput';
import Logo from '../Logo';

export default class LoginForm extends PureComponent {
  static propTypes = {
    email: string,
    password: string,
    emailError: string,
    passwordError: string,
    submitError: string,
    message: string,
    onEmailChange: func.isRequired,
    onPasswordChange: func.isRequired,
    onSubmit: func.isRequired,
  };

  componentDidMount() {
    const { email } = this.props;
    if (!email) {
      this.emailNode.focus();
    } else {
      this.passwordNode.focus();
    }
  }

  UNSAFE_componentWillReceiveProps({ emailError, passwordError }) {
    if (emailError) {
      this.emailNode.focus();
    } else if (passwordError) {
      this.passwordNode.focus();
    }
  }

  onEmailRef = ref => {
    this.emailNode = ref;
  };

  onPasswordRef = ref => {
    this.passwordNode = ref;
  };

  renderInfo() {
    const {
      submitError: a,
      emailError: b,
      passwordError: c,
      message: d,
    } = this.props;
    const text = [a, b, c, d].find(text => text != null);
    const isError = text === a;

    return (
      text && (
        <div
          className={classnames('login-info', {
            error: isError,
          })}
        >
          {text}
        </div>
      )
    );
  }

  render() {
    const { email, password, onEmailChange, onPasswordChange, onSubmit } =
      this.props;

    return (
      <form
        onSubmit={onSubmit}
        className="login-container login-container--background login-container--border login-form"
      >
        <div className="logo-wrapper">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <TextInput
          inputRef={this.onEmailRef}
          type="email"
          value={email}
          onChange={onEmailChange}
          placeholder={getText('login-email-placeholder')}
          autoComplete="username email"
        />
        <TextInput
          inputRef={this.onPasswordRef}
          type="password"
          value={password}
          onChange={onPasswordChange}
          placeholder={getText('login-password-placeholder')}
          autoComplete="current-password"
        />

        {this.renderInfo()}

        <button type="submit">{getText('login-button')}</button>

        <p className="forgot-wrapper">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={config.forgotPasswordUrl} target="_blank">
            {getText('login-forgot-password')}
          </a>
        </p>
      </form>
    );
  }
}
