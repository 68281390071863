import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => ({
  showPanel: () => dispatch({ type: '__DEV_PANEL_SHOW__' }),
});

const devPanelButton = WrappedElement => {
  class DevPanelButton extends Component {
    static DELAY = 1000;

    state = {
      disableClick: false,
    };

    componentWillUnmount() {
      this.stopTimer();
    }

    startTimer() {
      this.timer = window.setTimeout(() => {
        this.setState({
          disableClick: true,
        });
        this.props.showPanel();
      }, DevPanelButton.DELAY);
    }

    stopTimer() {
      window.clearTimeout(this.timer);
    }

    onMouseDown = () => {
      this.startTimer();
    };

    onMouseUp = () => {
      this.stopTimer();
    };

    onTouchStart = () => {
      this.startTimer();
    };

    onTouchEnd = () => {
      this.stopTimer();
    };

    onClick = e => {
      if (this.state.disableClick) {
        e.preventDefault();
        this.setState({
          disableClick: false,
        });
      }
    };

    render() {
      return React.cloneElement(WrappedElement, {
        onMouseDown: this.onMouseDown,
        onMouseUp: this.onMouseUp,
        onTouchStart: this.onTouchStart,
        onTouchEnd: this.onTouchEnd,
        onClick: this.onClick,
        onContextMenu: e => {
          e.preventDefault();
        },
      });
    }
  }

  return connect(null, mapDispatchToProps)(DevPanelButton);
};

export default devPanelButton;
