import { isString } from '@trmediaab/zebra-utils';
import config from 'config';
import isPlainObject from 'lodash/isPlainObject';
import reduce from 'lodash/reduce';

// Recursively remove properties with null value
export const rejectNullDeep = object => {
  if (!isPlainObject(object)) {
    return object;
  }
  return reduce(
    object,
    (acc, value, key) => {
      if (isPlainObject(value)) {
        acc[key] = rejectNullDeep(value);
      } else if (value !== null) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
};

export const getDisplayName = Component => {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return;
  }

  return Component.displayName || Component.name || 'Component';
};

/**
 * Returns a copy of an object with the given keys excluded
 */
export const omitKeys = (obj = {}, keys = []) => {
  // Ensure string keys
  const mappedKeys = new Set(keys.map(key => key.toString()));
  return reduce(
    obj,
    (acc, value, key) => {
      if (!mappedKeys.has(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
};

export const createAbsoluteUrl = path => {
  if (isString(path) && path.startsWith('http')) {
    return path;
  }

  const root = window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.host}`;

  return root + (path.indexOf('/') !== 0 ? '/' : '') + path;
};

export const createShopUrl = productId => {
  const currentUrl = window.location.href;
  return `${config.shopUrl}/buy?${
    productId ? `product_id=${productId}&` : ''
  }returnUrl=${currentUrl}&cancelUrl=${currentUrl}`;
};

export const parseQueryString = qs =>
  qs && qs.length > 1
    ? qs
        .slice(1)
        .split('&')
        .reduce((acc, pair) => {
          const a = pair.split('=');
          acc[a[0]] = a[1];
          return acc;
        }, {})
    : {};
