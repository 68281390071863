import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loader from 'shared/components/loader/Loader';
import { fetchContentList } from 'shared/redux/actions';
import { content } from 'shared/redux/selectors';

import ContentPage from './ContentPage';
import Error404Page from './Error404Page';
import FaqPage from './FaqPage';
import InstructionsPage from './InstructionsPage';
import withContentMenu from './withContentMenu';

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { slug },
    },
  } = ownProps;
  const category = 'root';
  return {
    pageId: content.getPageIdFromSlug(state, { slug, category }),
    pageTitle: content.getPageTitleFromSlug(state, { slug, category }),
    hasContentList: content.hasContentList(state, { category }),
    isInvalidSlug: content.isInvalidSlug(state, { slug, category }),
    isInstructionsPage: content.isInstructionsPage(state, { slug, category }),
    isFaqPage: content.isFaqPage(state, { slug, category }),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContentList,
    },
    dispatch,
  );

export default withContentMenu(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class ContentRoute extends Component {
      componentDidMount() {
        const { hasContentList, fetchContentList } = this.props;

        if (!hasContentList) {
          fetchContentList();
        }
      }

      renderPage() {
        const { pageId, isInstructionsPage, isFaqPage } = this.props;

        if (!pageId) {
          return null;
        }

        if (isInstructionsPage) {
          return <InstructionsPage {...this.props} />;
        }

        if (isFaqPage) {
          return <FaqPage {...this.props} />;
        }

        return <ContentPage {...this.props} />;
      }

      render() {
        const { isInvalidSlug, pageId } = this.props;

        if (isInvalidSlug) {
          return <Error404Page />;
        }

        return (
          <Fragment>
            <Loader cover visible={!pageId} />
            {this.renderPage()}
          </Fragment>
        );
      }
    },
  ),
);
