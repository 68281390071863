import { number } from 'prop-types';
import React from 'react';

const Percentage = ({ value }) => (
  <span className="progressbar__percent">{value}%</span>
);

const ProgressBar = ({ progress }) => (
  <div className="progressbar">
    <div className="progressbar__inner" style={{ width: `${progress}%` }}>
      {progress >= 10 && <Percentage value={progress} />}
    </div>
    {progress < 10 && <Percentage value={progress} />}
  </div>
);

ProgressBar.propTypes = {
  progress: number.isRequired,
};

export default ProgressBar;
