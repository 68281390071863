import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'shared/components/button/Button';
import { getErrorMessage } from 'shared/utils/errorUtils';
import { getText } from 'shared/utils/Text';

import ErrorCircle from './icon/ErrorCircle';
import ContentWrapper from './layout/ContentWrapper';

const BigWrapper = ({ children }) => (
  <ContentWrapper columnWidth="compact" columnHeight="full">
    <div className="flex flex--full-height flex--row-center">{children}</div>
  </ContentWrapper>
);

const SmallWrapper = ({ children }) => children;

const ErrorInfo = ({
  className = '',
  error,
  size,
  heading,
  friendlyErrorMessage,
  retry,
}) => {
  const Wrapper = size === 'big' ? BigWrapper : SmallWrapper;
  return (
    <Wrapper>
      <div
        className={classnames(className, 'error-info editorial', {
          'error-info--small': size === 'small',
          'error-info--big': size === 'big',
        })}
      >
        <ErrorCircle className={heading ? 'with-heading' : null} />
        <div>
          {heading && <h3>{heading}</h3>}
          <p className={size === 'small' ? 'text-small' : null}>
            {friendlyErrorMessage
              ? friendlyErrorMessage
              : error.name === 'ApiError'
              ? getErrorMessage(error)
              : error.message
              ? error.message
              : getText('error-message-generic')}
          </p>
          {retry && (
            <Button onClick={retry} className="retry-button">
              {getText('error-retry')}
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

ErrorInfo.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  size: PropTypes.oneOf(['small', 'big']),
  heading: PropTypes.string,
  friendlyErrorMessage: PropTypes.string,
  className: PropTypes.string,
  retry: PropTypes.func,
};

ErrorInfo.defaultProps = {
  size: 'small',
};

export default ErrorInfo;
